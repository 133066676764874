/** FONTS **/
$font-primary: "Montserrat", sans-serif;
$font-secondary: $font-primary;

/** COLORS **/
$color-gray: #3F5272;
$color-gray-light: #DFDFDF;
$color-white: #ffffff;

$color-blue: #1A2C49;
$color-red: #FCDFD7;
$color-green: #D4ECDC;
$color-orange: #C38130;


$color-primary: $color-blue;
$color-secondary: $color-orange;
$color-tertiary: $color-gray;


/** SIZES **/
$max-site-width: 1390px;
$large-desktop-width: 1260px;
$desktop-width: 960px;
$tablet-width: 580px;
$phone-width: 90%;

/** Typos **/
$base-font-size: 15px;

/** ANIMATIONS **/
$anim-duration: 0.2s;